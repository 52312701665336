py-overview-list-item {
  &.selectable,
  cx-generic-link a {
    border-radius: 8px !important;
    box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.5), 0px 0px 1px 0px rgba(0, 0, 0, 0.25) !important;

    &.selected,
    &:hover {
      outline: 1px solid var(--py-color-line-hard) !important;
      box-shadow: 0px 18px 40px -20px rgba(0, 0, 0, 0.7), 0px 0px 1px 0px rgba(0, 0, 0, 0.25) !important;
    }
  }
}
