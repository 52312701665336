@import 'declarations';

py-back-to-top {
  > div {
    box-shadow: unset !important;
    background-color: var(--py-color-global-active) !important;

    &:hover {
      background-color: var(--py-color-global-active-hover) !important;
    }
  }
}
