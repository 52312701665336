@use 'sass:map';

$button-shadow-shape: 0 2px 4px;
$button-shadow-shape-large: 0 4px 8px;

.btn {
  &-primary {
    @include button-colors(
      var(--py-color-white),
      var(--py-color-button-primary),
      var(--py-color-button-primary),
      var(--py-color-button-primary),
      var(--py-color-primary-800),
      var(--py-color-button-primary),
      var(--py-color-primary-800),
      var(--py-color-button-primary),
      var(--py-color-primary-800),
      $button-shadow-shape rgba(#1b7e5a, 0.04),
      $button-shadow-shape-large rgba(#1b7e5a, 0.16)
    );
  }

  &-secondary {
    @include button-colors(
      var(--py-color-white),
      var(--py-color-button-secondary),
      var(--py-color-button-secondary),
      var(--py-color-button-secondary),
      var(--py-color-secondary-800),
      var(--py-color-button-secondary),
      var(--py-color-secondary-800),
      var(--py-color-button-secondary),
      var(--py-color-secondary-800),
      $button-shadow-shape rgba(#092a1e, 0.04),
      $button-shadow-shape-large rgba(#092a1e, 0.16)
    );
  }

  &-danger {
    @include button-colors(
      var(--py-color-white),
      var(--py-color-button-danger),
      var(--py-color-button-danger),
      var(--py-color-button-danger),
      var(--py-color-danger-700),
      var(--py-color-button-danger),
      var(--py-color-button-700),
      var(--py-color-button-danger),
      var(--py-color-button-700),
      $button-shadow-shape rgba(#b70000, 0.04),
      $button-shadow-shape-large rgba(#b70000, 0.16)
    );
  }

  &-plain-primary {
    @include button-outline-colors(
      var(--py-color-button-primary),
      var(--py-color-button-primary),
      var(--py-color-white),
      var(--py-color-line-steady),
      var(--py-color-global-active)
    );

    box-shadow: none !important;
  }

  &-plain-secondary {
    @include button-outline-colors(
      var(--py-color-black),
      var(--py-color-black),
      var(--py-color-white),
      var(--py-color-line-steady),
      var(--py-color-global-active)
    );

    box-shadow: none !important;
  }

  &-plain-danger {
    @include button-outline-colors(
      var(--py-color-button-danger),
      var(--py-color-button-danger),
      var(--py-color-white),
      var(--py-color-line-steady),
      var(--py-color-global-active)
    );

    box-shadow: none !important;
  }

  &-ternary {
    // TODO ESVCX-5948 - remove
    @include button-colors(
      var(--py-color-black),
      var(--py-color-white),
      var(--py-color-grey-light),
      var(--py-color-white),
      var(--py-color-primary),
      var(--py-color-white),
      var(--py-color-primary),
      var(--py-color-grey-lighter),
      var(--py-color-primary)
    );

    box-shadow: none !important;

    &:not(:active):not(:focus):not(:hover):not(:disabled) {
      border-width: 1px;
      &:not(.btn-icon) {
        padding: calc(#{$padding-y} + 1px) calc(#{$padding-x} + 1px); //increasing padding to compensate for smaller border
      }
      &.btn-icon {
        padding: calc(#{$icon-padding} + 1px); //increasing padding to compensate for smaller border
      }
    }

    &:disabled {
      border: 2px solid var(--py-color-grey-light);
    }
  }
}
