@import 'declarations';

cx-page-slot,
[cx-page-slot] {
  &.FooterLogo {
    flex-direction: row !important;
    gap: 16px;

    &::before {
      content: none !important;
    }

    a {
      color: var(--py-color-primary-400) !important;

      i {
        color: var(--py-color-white) !important;
      }
    }

    py-banner {
      flex: none !important;

      &.site-logo {
        img {
          max-width: 168px !important;
          filter: brightness(0%) invert(100%);
        }
      }
    }

    py-paragraph {
      flex: none !important;

      @include media-breakpoint-down(md) {
        display: initial !important;
      }

      .paragraph-holder {
        font-size: 16px;
        div {
          color: var(--py-color-grey-200) !important;

          a:hover {
            color: var(--py-color-info-200) !important;
          }
        }
      }
    }

    @include media-breakpoint-down(md) {
      height: auto !important;
      flex-direction: column !important;
      padding-block: 50px;

      > :first-child,
      > :last-child {
        margin-left: initial;
        margin-right: initial;
      }
    }

    @media print {
      page-break-before: always !important;

      py-link-collection,
      py-country-selector {
        display: none !important;
      }
    }
  }

  &.Footer .extended-footer-wrapper {
    flex-direction: column !important;
    align-items: center;
    padding: 20px;
    border-top: 1px solid var(--py-color-primary-600) !important;
    border-bottom: 1px solid var(--py-color-primary-600) !important;

    > div {
      justify-content: center !important;
    }

    py-country-selector {
      display: none !important;
    }
  }
}
