@import 'declarations';

cx-page-layout.LandingPageTemplate {
  .StartCategoryPuffs {
    margin-top: 32px !important;

    @include media-breakpoint-down(sm) {
      margin-top: 16px !important;
    }

    py-puff-collection {
      .puff-collection-holder {
        padding-top: 32px !important;
        border-bottom: none !important;

        @include media-breakpoint-down(sm) {
          padding-top: 8px !important;
        }
      }
    }
  }
}
