$py-brand-colors: (
  // Primary
  'primary-900': #060a16,
  'primary-800': #0c132c,
  'primary-700': #131d42,
  'primary-600': #192658,
  'primary-500': #1f306e,
  'primary-400': #47558a,
  'primary-300': #96a0c1,
  'primary-200': #d2d7ea,
  'primary-100': #e6eaf8,

  // Secondary
  'secondary-900': #0b2825,
  'secondary-800': #16504b,
  'secondary-700': #217d74,
  'secondary-600': #2ba196,
  'secondary-500': #36c9bb,
  'secondary-400': #86dfd6,
  'secondary-300': #afe9e4,
  'secondary-200': #d7f4f1,
  'secondary-100': #ebfaf8,

  // Button colors
  'button-primary': #1f306e,
  'button-secondary': #217d74,
  'button-danger': #f43f3f,
  'button-locked': #e5e5e6,

  // Line
  'line-hard': #828385,
  'line-steady': #cbcbcd,
  'line-soft': #e5e5e6,

  // Global
  'global-selected': #e6eaf8,
  'global-hover': #d2d7ea,
  'global-active': #1f306e,
  'global-active-hover': #131d42,
  'global-tooltip': #646568,

  // Deprecated colors from legacy designs - DO NOT USE IN NEWLY DEVELOPED CODE
  // TODO ESVCX-5948 - remove legacy colors
  // Deprecated colors START
  'primary-dark': #131d42,
  'primary': #1f306e,
  'primary-light': #96a0c1,
  'secondary-darker': #0b2825,
  'secondary-dark': #16504b,
  'secondary': #217d74,
  'secondary-light': #29bd87
) !default;

:root {
  @include generate-py-variables($py-brand-colors);
}
