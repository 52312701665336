py-header-navigation-item {
  border-radius: 4px !important;

  &.active {
    background-color: var(--py-color-grey-200) !important;
  }

  &:hover {
    background-color: var(--py-color-grey-100) !important;
    text-decoration: underline;
  }
}
