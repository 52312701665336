py-my-page-menu-list-item {
  li {
    a,
    a:visited,
    .collapsed-holder .collapsed-item {
      color: var(--py-color-black) !important;
      &:hover {
        background-color: var(--py-color-global-hover) !important;
      }
      &.active {
        background-color: var(--py-color-global-selected) !important;
      }
    }
  }
}
